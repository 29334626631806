import React, { useEffect, useState } from 'react';

import GravityForm from '../GravityForm/GravityForm';
import { WpClientRequest } from '@/lib/wordpress/wpClientRequest';
import { useGlobalContext } from '@/lib/globalContext';
import styled from 'styled-components';
import { variables } from 'oddsgate-ds';

// override size imput in mobile and get icon in button
const WrapToChangeSizeInputOnlyHere = styled.div`
    position: relative;
    input {
        font-size: 16px !important;
    }
    @media only screen and (max-width: ${variables.responsiveMedia}) {
        button {
            position: absolute;
            top: 10%;
            i {
                display: block !important;
            }
        }
    }
`;
const Newsletter = () => {
    const state = useGlobalContext();
    const [formData, setFormData] = useState<any>();
    const lang = state.lang;

    const formID = state?.dict?.custom?.newsletterForm || 2;

    useEffect(() => {
        WpClientRequest({
            post_type: `gf/v2/forms/${formID}/`,
            isContent: false,
            isForms: true,
            variables: {
                lang,
            },
        }).then((res) => {
            setFormData({ formId: formID, formData: res });
        });
    }, [lang]);

    return (
        <WrapToChangeSizeInputOnlyHere>
            {formData && <GravityForm {...formData} />}
        </WrapToChangeSizeInputOnlyHere>
    );
};

export default Newsletter;
