//@ts-nocheck

'use client';

import { Loader, debounce, useMediaMatch } from 'oddsgate-ds';
import { Suspense, useEffect, useRef, useState } from 'react';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGlobalContext } from '@/lib/globalContext';

export type IBlockSocialDrag = {
    attrs: {
        className?: string;
        style?: Record<string, unknown>;
    };
};

export default function BlockSocialDrag({ attrs }: IBlockSocialDrag) {
    const scene = useRef();
    const [initMatter, setInitMatter] = useState();

    // const cirlceSize = windowSize?.height * 0.1;
    const state = useGlobalContext();

    useEffect(() => {
        const fetchModules = async () => {
            try {
                const Matter = (await import('matter-js')).default;
                setInitMatter(Matter);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchModules();
    }, []);

    const isMobile = useMediaMatch();
    useEffect(() => {
        const sceneElement = scene.current as any;
        if (
            !initMatter ||
            (sceneElement && sceneElement.querySelector('canvas'))
        )
            return;

        const Matter = initMatter;

        const Engine = Matter.Engine,
            Events = Matter.Events,
            Render = Matter.Render,
            Runner = Matter.Runner,
            MouseConstraint = Matter.MouseConstraint,
            Mouse = Matter.Mouse,
            Composite = Matter.Composite,
            Common = Matter.Common,
            Bodies = Matter.Bodies;

        const engine = Engine.create(),
            world = engine.world;

        // create renderer
        const render = Render.create({
            element: sceneElement,
            engine: engine,
            options: {
                wireframes: false,
                width: sceneElement.clientWidth,
                height: sceneElement.clientHeight,
                background: 'transparent',
            },
        });

        Render.run(render);

        // create runner
        const runner = Runner.create();
        Runner.run(runner, engine);

        world.bodies = [];

        // these static walls will not be rendered in this sprites example, see options
        const createWalls = () => {
            Composite.add(world, [
                Bodies.rectangle(0, 0, 1, sceneElement.clientHeight * 2, {
                    isStatic: true,
                    render: { fillStyle: 'transparent' },
                }),
                Bodies.rectangle(0, 0, sceneElement.clientWidth * 2, 1, {
                    isStatic: true,
                    render: { fillStyle: 'transparent' },
                }),
                Bodies.rectangle(
                    sceneElement.clientWidth - 1,
                    0,
                    1,
                    sceneElement.clientHeight * 2,
                    { isStatic: true, render: { fillStyle: 'transparent' } }
                ),
                Bodies.rectangle(
                    0,
                    sceneElement.clientHeight - 1,
                    sceneElement.clientWidth * 2,
                    1,
                    { isStatic: true, render: { fillStyle: 'transparent' } }
                ),
            ]);
        };
        createWalls();

        const createElements = () => {
            const radius = !isMobile ? 120 : 60;
            const scale = !isMobile ? 0.85 : 0.4;

            const buttonSize = {
                width: !isMobile ? 240 : 174,
                height: !isMobile ? 88 : 58,
            };

            const bodies = [];
            if (state && state?.themeOptions && state?.themeOptions?.socials) {
                Object.entries(state.themeOptions.socials).map((social) => {
                    bodies.push(
                        Bodies.circle(
                            Common.random(0, sceneElement.clientWidth),
                            0,
                            radius,
                            {
                                density: 0.01,
                                frictionAir: 0.06,
                                restitution: 0.3,
                                friction: 0.01,
                                mass: 5,
                                render: {
                                    fillStyle: '#FF4552',
                                    sprite: {
                                        texture: `/assets/components/socials/${social[0]}.svg`,
                                        xScale: scale,
                                        yScale: scale,
                                    },
                                },
                                url: social[1],
                            }
                        )
                    );
                });

                // bodies.push(Bodies.rectangle(Common.random(0, sceneElement.clientWidth), Common.random(0, sceneElement.clientHeight), buttonSize.width, buttonSize.height, {
                //   density: 0.01,
                //   frictionAir: 0.06,
                //   restitution: 0.3,
                //   friction: 0.01,
                //   // chamfer: { radius: [50, 50, 50, 50] },
                //   render: {
                //     // strokeStyle: '#ffffff',
                //     sprite: {
                //       texture: `/assets/components/socials/button.svg`,
                //       xScale: scale,
                //       yScale: scale,
                //     }
                //   },
                //   url: state.dict?.custom?.contactLink
                // }))
            }

            Composite.add(world, bodies);
        };

        createElements();

        // add mouse control
        const mouse = Mouse.create(render.canvas),
            mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                // collisionFilter: {mask: 0b10}, //removes drag and drop
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false,
                    },
                },
            });

        Composite.add(world, mouseConstraint);

        // keep the mouse in sync with rendering
        render.mouse = mouse;

        // Detect clicks vs. drags
        let isDragging = false;

        const setDragging = () => {
            isDragging = true;
        };
        const unsetDragging = () => {
            isDragging = false;
        };

        const mouseUpEvent = (event: any) => {
            if (isDragging) return;
            const mouseConstraint = event.source;
            const bodies = engine.world.bodies;
            if (!mouseConstraint.body) {
                for (let i = 0; i < bodies.length; i++) {
                    const body = bodies[i];
                    // Check if clicked or dragged
                    if (
                        Matter.Bounds.contains(
                            body.bounds,
                            mouseConstraint.mouse.position
                        )
                    ) {
                        const bodyUrl = body.url;
                        // Hyperlinking feature
                        if (bodyUrl != undefined) {
                            //window.location.href = bodyUrl;
                            window.open(bodyUrl, '_blank');
                        }
                        break;
                    }
                }
            }
        };

        // Create a On-Mouseup Event-Handler
        Events.on(mouseConstraint, 'mouseup', mouseUpEvent);
        Events.on(mouseConstraint, 'mousemove', setDragging);
        Events.on(mouseConstraint, 'mousedown', unsetDragging);

        // fit the render viewport to the scene
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: sceneElement.clientWidth, y: sceneElement.clientHeight },
        });

        const handleResize = debounce(() => {
            isDragging = false;
            if (!isMobile) {
                // set canvas size to new values
                render.canvas.width = sceneElement.clientWidth;
                render.canvas.height = sceneElement.clientHeight;

                world.bodies.forEach((body) => {
                    Composite.remove(engine.world, body);
                });
                world.bodies = [];

                // reposition ground
                createWalls();
                createElements();
            }
        }, 250);

        ScrollTrigger.create({
            trigger: sceneElement,
            top: 'top top',
            bottom: 'bottom bottom',
            onEnter: (self) => {
                handleResize();
            },
        });

        window.addEventListener('resize', handleResize);

        return () => {
            Events.off(mouseConstraint, 'mouseup', mouseUpEvent);
            Events.off(mouseConstraint, 'mousemove', setDragging);
            Events.off(mouseConstraint, 'mousedown', unsetDragging);

            Matter.Render.stop(render);
            Matter.Runner.stop(runner);

            sceneElement.querySelector('canvas').remove();

            world.bodies.forEach((body) => {
                Matter.Composite.remove(engine.world, body);
            });
            world.bodies = [];

            window.removeEventListener('resize', handleResize);
        };
    }, [initMatter, state]);

    return (
        <Suspense
            fallback={<Loader label={state?.dict?.common?.loading}></Loader>}>
            <div className={attrs?.className}>
                <div
                    ref={scene as any}
                    style={{ width: '100%', height: '30rem' }}
                />
            </div>
        </Suspense>
    );
}
