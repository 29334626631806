import { ErrorMessage, Field } from 'formik';

import CustomErrorMessage from './CustomErrorMessage';
import { FormField } from 'oddsgate-ds';
import { IInputs } from '../fieldProps';
import cn from 'classnames';
import getGfFieldId from '@/lib/wordpress/forms/getGfFieldId';
import getGfHiddenClassName from '@/lib/wordpress/forms/getHiddenClassName';

/**
 * Render GravityForms File field component.
 *
 * @param  {object}        props               GravityForm Select field as props.
 * @param  {string}        props.cssClass     GravityForm field wrapper class.
 * @param  {string}        props.description   GravityForm field description.
 * @param  {string|number} props.id            GravityForm field id.
 * @param  {boolean}       props.isRequired    GravityForm field is required.
 * @param  {string}        props.label         GravityForm field label.
 * @param  {boolean}       props.visibility    GravityForm visibility option.
 * @param  {Function}      props.setFieldValue Formik function to set state.
 * @return {Element}                           The File component.
 */
export default function File({
    cssClass,
    description,
    id,
    isRequired,
    label,
    visibility,
    setFieldValue,
    allowedExtensions,
    value,
}: IInputs) {
    const fieldId = getGfFieldId(id);
    const isHiddenClass = getGfHiddenClassName(visibility);
    const thisClassName = cn(cssClass, isHiddenClass);
    // <div className={cn("input-field", thisClassName)}>
    return (
        <FormField label={label} id={fieldId}>
            <Field
                id={fieldId}
                name={fieldId}
                type="file"
                required={isRequired}
                className={thisClassName}
                value={value}
                accept={allowedExtensions}
                onChange={(e: any) => {
                    // Save to _filedata here so we don't corrupt state.
                    setFieldValue(
                        `${fieldId}_filedata`,
                        e.currentTarget.files[0]
                    );
                }}
            />
            {description && <p>{description}</p>}
            <ErrorMessage name={fieldId} component={CustomErrorMessage} />
        </FormField>
    );
}
// </div>
