'use client';

import { Spacer } from 'oddsgate-ds';
import { CSSProperties } from 'styled-components';

export interface ISpacer extends ISCSpacer {
    id?: string;
    anchor?: string;
    color?: string;
    height?: string | number;
    customHeight?: string;
    customHeightTablet?: string;
    customHeightMobile?: string;
    className?: string;
    style?: CSSProperties;
    gradient?: string;
}

export type ISCSpacer = {
    $color: ISpacer['color'];
    $height: ISpacer['height'];
    $customHeightTablet: ISpacer['customHeightTablet'];
    $customHeightMobile: ISpacer['customHeightMobile'];
};

export type IBlockSpacer = {
    attrs: ISpacer;
};

export default function BlockSpacer({ attrs }: any) {
    const {
        anchor,
        customHeight = '8rem',
        customHeightTablet,
        customHeightMobile,
        className,
        gradient,
    } = attrs || {};

    return (
        <Spacer
            customHeight={customHeight}
            customHeightTablet={customHeightTablet}
            customHeightMobile={customHeightMobile}
            id={anchor || undefined}
            className={className}
            gradient={gradient}
        />
    );
}
