import {
    Button,
    Column,
    Icon,
    Loader,
    Modal,
    Row,
    variables,
} from 'oddsgate-ds';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import Fields from './Fields';
import { IGravityForms } from '@/lib/wordpress/forms/types';
import RenderReCaptcha from '@/lib/wordpress/forms/recaptcha';
import getGfFormDefaults from '@/lib/wordpress/forms/getGfFormDefaults';
import getGfFormValidationSchema from '@/lib/wordpress/forms/getGfFormValidationSchema';
import processGfFormSubmission from '@/lib/wordpress/forms/api/processGfFormSubmission';
import { styled } from 'styled-components';
import { useGlobalContext } from '@/lib/globalContext';

interface IForm {
    $disabled: boolean;
}
const StyledForm = styled(Form)<IForm>`
    position: relative;

    ${({ $disabled }) =>
        $disabled &&
        `
    & input,
    & button{
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events:none;
    }
  `}
`;
const StyledButton = styled(Button)`
    position: absolute;
    top: 50%;
    right: 1rem;
    padding: 1.5rem;
    z-index: 1;

    color: ${variables.colors.third10};

    transform: translateY(-50%);
`;

export default function GravityForm({ formData, formId }: IGravityForms) {
    const state = useGlobalContext();
    const recaptcha: any = RenderReCaptcha();
    const [isOpen, setIsOpen] = useState(false);

    const cssClass = formData?.cssClass || '';
    const title = formData?.title || '';
    const description = formData?.description || '';
    const fields = formData?.fields;
    const button = formData?.button;

    // Setup form defaults and validation based on GravityForm field data.
    const fieldData = fields;
    const formValidationSchema = getGfFormValidationSchema(fieldData);
    const fieldDefaults = getGfFormDefaults(fieldData);

    const [errorMessage, setErrorMessage] = useState('');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    useEffect(() => {
        if ((errorMessage || confirmationMessage) && !isOpen) {
            setIsOpen(true);
        }
    }, [errorMessage, confirmationMessage, isOpen]);

    /**

   * Handle form submission.
   *
   * @author Miew
   * @param {object} values Form values.
   */
    async function handleFormSubmission(
        values: any,
        {
            setSubmitting,
            setErrors,
            setStatus,
            resetForm,
        }: {
            setSubmitting: any;
            setErrors: any;
            setStatus: any;
            resetForm: any;
        }
    ) {
        setErrorMessage('');
        setConfirmationMessage('');

        if (process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY) {
            await recaptcha
                .execute(process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY, {
                    action: 'submit',
                })
                .then(function (token: string) {
                    values['captcha'] = token;
                });
        }

        Object.keys(values).forEach((key) => {
            if (
                values[key] &&
                typeof values[key] === 'string' &&
                values[key].indexOf('post_title') > -1
            ) {
                values[key] = document && document.title;
            }
        });

        const response = await processGfFormSubmission({
            formId: formId,
            entryData: values,
            fieldData: fieldData,
        });
        if (response?.error) {
            setStatus({ success: false });
            setSubmitting(false);
            setErrorMessage(response.errorMessage);
        } else {
            setConfirmationMessage(response.confirmationMessage);
            setStatus({ success: true });
            setSubmitting(false);
            resetForm({ values: fieldDefaults });
        }
    }

    // const handleOnChange = (event: FormEvent) => {
    //   console.log("Form::onChange", event);
    // };

    return (
        <Formik
            id={formId}
            initialValues={fieldDefaults}
            validationSchema={formValidationSchema}
            onSubmit={handleFormSubmission}>
            {({
                values,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                /* and other goodies */
            }) => (
                <StyledForm
                    className="position-relative"
                    encType="multipart/form-data"
                    $disabled={isSubmitting}>
                    {fieldData && Object.keys(fieldData).length > 0 && (
                        <Fields
                            fields={fieldData}
                            setFieldValue={setFieldValue}
                            values={values}
                            submitElement={
                                <>
                                    {button?.absoluteButton && (
                                        <StyledButton
                                            variant={'icon'}
                                            type="submit"
                                            disabled={isSubmitting}>
                                            {!button?.iconButton &&
                                            button?.text ? (
                                                button?.text ||
                                                (
                                                    state?.dict
                                                        ?.common as Record<
                                                        string,
                                                        string
                                                    >
                                                )?.sendMessage
                                            ) : (
                                                <Icon
                                                    icon="icon-arrow-right"
                                                    className="d-block"></Icon>
                                            )}
                                        </StyledButton>
                                    )}
                                </>
                            }
                        />
                    )}

                    {!button?.absoluteButton && (
                        <Row fluid={true}>
                            <Column>
                                <div className="d-flex align-items-center justify-content-end">
                                    {isSubmitting && (
                                        <Loader className="mt-6"></Loader>
                                    )}
                                    <Button
                                        variant={'primary'}
                                        type="submit"
                                        disabled={isSubmitting}
                                        className="mt-6">
                                        {!button?.iconButton && button?.text ? (
                                            button?.text ||
                                            (
                                                state?.dict?.common as Record<
                                                    string,
                                                    string
                                                >
                                            )?.sendMessage
                                        ) : (
                                            <Icon
                                                icon="icon-arrow-right"
                                                className="d-block"></Icon>
                                        )}
                                    </Button>
                                </div>
                            </Column>
                        </Row>
                    )}
                    <Modal
                        open={isOpen}
                        onClose={() => {
                            setErrorMessage('');
                            setConfirmationMessage('');
                            setIsOpen(false);
                        }}>
                        {!!errorMessage && (
                            <div
                                className="feedback color-third50"
                                dangerouslySetInnerHTML={{
                                    __html: errorMessage,
                                }}
                            />
                        )}
                        {!!confirmationMessage && (
                            <div
                                className="feedback color-third50"
                                dangerouslySetInnerHTML={{
                                    __html: confirmationMessage,
                                }}
                            />
                        )}
                    </Modal>
                </StyledForm>
            )}
        </Formik>
    );
}
