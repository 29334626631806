'use client';

import { Marquee } from 'oddsgate-ds';

export type IBlockMarquee = {
    attrs: {
        anchor?: string;
        text?: string;
        repeat?: number;
        duration?: number;
        className?: string;
        style?: Record<string, unknown>;
    };
};

export default function BlockMarquee({ attrs }: IBlockMarquee) {
    const { text, repeat, duration, className, style } = attrs || {};

    return (
        <Marquee
            text={text}
            repeat={repeat || 20}
            duration={duration || 60}
            className={className}
            style={style}
        />
    );
}
